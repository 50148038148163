import { useCallback, useEffect, useRef } from 'react';
import type { UseFormReturn } from 'react-hook-form';

export const useFormReset = (form: UseFormReturn<any>) => {
  const { reset } = form;
  const { isDirty } = form.formState;
  const ref = useRef<any>(undefined);

  useEffect(() => {
    // Form is no longer dirty, check if there is a promise we need to resolve.
    if (!isDirty && ref.current) {
      ref.current();
      ref.current = undefined;
    }
  }, [isDirty]);

  return useCallback(
    (values: any) => {
      return new Promise<void>((resolve) => {
        if (isDirty) {
          // Store the resolve function to be called when isDirty has changed.
          ref.current = resolve;
          // Reset the form with the current values.
          reset(values);
        } else {
          // The form is not dirty, so skip resetting it.
          resolve();
        }
      });
    },
    [isDirty, reset],
  );
};

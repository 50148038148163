import { gql } from '@apollo/client';

import {
  MUTATION_FRAGMENT,
  PARTY_FRAGMENT,
  REGISTER_FRAGMENT,
  SHARE_BATCH_FRAGMENT,
  SHARE_TYPE_FRAGMENT,
  SHARE_TYPE_VERSION_FRAGMENT,
} from '../fragments';

export const REGISTER_QUERY = gql`
  query RegisterQuery($registerId: ID!, $atDate: Date, $mutationId: String) {
    register(registerId: $registerId, atDate: $atDate, mutationId: $mutationId) {
      ...RegisterFields
    }
  }
  ${REGISTER_FRAGMENT}
`;

export const REGISTER_DISTRIBUTION_CHART_QUERY = gql`
  query RegisterDistributionChart(
    $registerId: ID!
    $groupBy: DistributionChartGroupBy!
    $filterBy: DistributionChartFilterBy
  ) {
    registerDistributionChart(registerId: $registerId, groupBy: $groupBy, filterBy: $filterBy) {
      pieces {
        quantity
        quantityPercentage

        amount {
          currencyCode
          units
          cents
          precision
        }
        amountPercentage

        party {
          ...PartyFields
        }
      }
    }
  }
  ${PARTY_FRAGMENT}
`;

const PARTY_MUTATION_HISTORY_FRAGMENT = gql`
  fragment PartyMutationHistoryFields on PartyMutationHistory {
    party {
      ...PartyFields
    }
    partyUpdateHistory {
      date
      party {
        ...PartyFields
      }
      updateFields
    }
    mutations {
      mutation {
        ...MutationFields
      }
      delta {
        ...ShareBatchFields
      }
      result {
        ...ShareBatchFields
      }
      shareTypeUpdate {
        before {
          ...ShareTypeVersionFields
        }
        after {
          ...ShareTypeVersionFields
        }
      }
    }
  }
  ${PARTY_FRAGMENT}
  ${MUTATION_FRAGMENT}
  ${SHARE_BATCH_FRAGMENT}
  ${SHARE_TYPE_VERSION_FRAGMENT}
`;

export const REGISTER_OF_SHAREHOLDERS_QUERY = gql`
  query RegisterOfShareholders($registerId: ID!, $locale: Locale!) {
    registerOfShareholders(registerId: $registerId, locale: $locale) {
      register {
        ...RegisterFields
      }
      partyUpdateHistory {
        date
        party {
          ...PartyFields
        }
        updateFields
      }
      capitalHistory {
        authorized {
          date
          shares {
            shareType {
              ...ShareTypeFields
            }
            amount
          }
        }
        issued {
          date
          shares {
            shareType {
              ...ShareTypeFields
            }
            amount
          }
        }
        paid {
          date
          shares {
            shareType {
              ...ShareTypeFields
            }
            amount
          }
        }
        unpaid {
          date
          shares {
            shareType {
              ...ShareTypeFields
            }
            amount
          }
        }
      }
      partyMutationHistory {
        ...PartyMutationHistoryFields
      }
      pledgeeMutationHistory {
        ...PartyMutationHistoryFields
      }
      usufructuaryMutationHistory {
        ...PartyMutationHistoryFields
      }
      guardianMutationHistory {
        ...PartyMutationHistoryFields
      }
      partyAttachmentStates {
        party {
          ...PartyFields
        }
        attachments {
          attachedAt
          requiredBy {
            ...PartyFields
          }
          bailiff {
            ...PartyFields
          }
          type
          shareSeries {
            ...ShareBatchFields
          }
        }
      }

      locale
    }
  }
  ${SHARE_TYPE_FRAGMENT}
  ${SHARE_BATCH_FRAGMENT}
  ${PARTY_MUTATION_HISTORY_FRAGMENT}
  ${REGISTER_FRAGMENT}
  ${PARTY_FRAGMENT}
`;

export const EXPORT_REGISTER_OF_SHAREHOLDERS_QUERY = gql`
  query ExportRegisterOfShareholders($registerId: ID!, $locale: Locale!) {
    exportRegisterOfShareholders(registerId: $registerId, locale: $locale)
  }
`;

export const EXPORT_SHAREHOLDER_CSV_QUERY = gql`
  query ExportShareholderCsv(
    $registerId: ID!
    $locale: Locale!
    $grouped: Boolean
    $extended: Boolean
  ) {
    exportShareholderCsv(
      registerId: $registerId
      locale: $locale
      grouped: $grouped
      extended: $extended
    )
  }
`;

export const EXPORT_SHAREHOLDER_EXTRACT_QUERY = gql`
  query ExportShareholderExtract($registerId: ID!, $partyId: ID!, $locale: Locale!) {
    exportShareholderExtract(registerId: $registerId, partyId: $partyId, locale: $locale)
  }
`;

export const RECALCULATE_REGISTER_MUTATION = gql`
  mutation RecalculateRegisterMutation($registerId: ID!) {
    recalculateRegister(registerId: $registerId) {
      ...RegisterFields
    }
  }
  ${REGISTER_FRAGMENT}
`;

export const UPDATE_REGISTER_MUTATION = gql`
  mutation UpdateRegisterMutation($registerId: ID!, $register: RegisterInput!) {
    updateRegister(registerId: $registerId, register: $register) {
      replacement
    }
  }
`;

export const SIGN_REGISTER_MUTATION = gql`
  mutation SignRegisterMutation($registerId: ID!) {
    signRegister(registerId: $registerId) {
      ...RegisterFields
    }
  }
  ${REGISTER_FRAGMENT}
`;

export const UNSIGN_REGISTER_MUTATION = gql`
  mutation UnsignRegisterMutation($registerSignatureId: ID!) {
    unsignRegister(registerSignatureId: $registerSignatureId) {
      ...RegisterFields
    }
  }
  ${REGISTER_FRAGMENT}
`;

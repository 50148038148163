import { useMutation } from '@apollo/client';
import type { DeedPassed, LegalEntity, Maybe, RegisterMutation } from '@elseu/sdu-evidend-graphql';
import { AttachmentType, DocumentType, MutationType } from '@elseu/sdu-evidend-graphql';
import { Box, Button, Checkbox } from '@elseu/sdu-titan';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { DatePickerField } from 'components/FormFields/DatePickerField';
import { InputField } from 'components/FormFields/InputField';
import { SelectField } from 'components/FormFields/SelectField';
import { useWizardContext, WizardFooter, WizardHeader } from 'components/Wizard';
import dayjs from 'dayjs';
import type { DocumentUploadFormValues } from 'forms/document/DocumentUploadForm';
import { DocumentUploadForm, documentUploadFormSchema } from 'forms/document/DocumentUploadForm';
import { dateBasicValidation } from 'forms/helpers/dateTransform';
import { setFormErrors } from 'forms/helpers/setFormErrors';
import { mapLegalEntityToLegalEntityInput } from 'graphql/input-mappers/LegalEntity';
import { DELETE_FILE } from 'graphql/queries/document';
import { UPDATE_REGISTER_MUTATION } from 'graphql/queries/register';
import { formatDocumentType } from 'helpers/document';
import { formatMutationType, mutationLabelObject } from 'helpers/formatMutation';
import { useFormReset } from 'hooks/useFormReset';
import { useGlobalError } from 'hooks/useGlobalError';
import { useLabels } from 'hooks/useLabels';
import { useUploadDocument } from 'hooks/useUpdateDocument';
import { useUpdateLegalEntity } from 'legalEntities/hooks/useUpdateLegalEntity';
import { cloneDeep, isEqual } from 'lodash';
import type { MutationInputFields } from 'mutations/mutationFields';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import type { DeleteFile, DeleteFileVariables } from 'types/graphql/DeleteFile';
import type {
  UpdateRegisterMutation,
  UpdateRegisterMutationVariables,
} from 'types/graphql/UpdateRegisterMutation';
import { attachmentOptions } from 'wizards/Attachment/generators';
import * as yup from 'yup';

type UploadDocumentFormValues = {
  document: Maybe<DocumentUploadFormValues>;
  incorporatedOn?: Date;
  acknowledgedDate?: Date;
  effectiveDate?: Date;
  attachmentType?: AttachmentType;
  attachedAt?: string | null;
  title?: string | null;
  isUpdateOfAoA?: boolean | null;
  replacement?: boolean | null;
};

interface CustomMutationFieldsProps {
  mutationType: MutationType;
  shouldAcknowledge: boolean;
}
const CustomMutationFields = ({ mutationType, shouldAcknowledge }: CustomMutationFieldsProps) => {
  const { watch, setValue } = useFormContext();
  const replacement = watch(`replacement`);
  const hasReplacementOption = useMemo(
    () => [MutationType.INCORPORATION, MutationType.ONBOARDING].includes(mutationType),
    [mutationType],
  );
  return (
    <>
      {[MutationType.ATTACHMENT, MutationType.END_ATTACHMENT].includes(mutationType) && (
        <InputField
          label={t`Tijdstip overeenkomst`}
          name="attachedAt"
          placeholder={t`uu:mm`}
          required={mutationType === MutationType.ATTACHMENT}
          spaceAfter={6}
        />
      )}
      {mutationType === MutationType.ATTACHMENT && (
        <SelectField
          required
          defaultValue={AttachmentType.PREJUDGEMENT}
          label={t`Beslagtype`}
          name="attachmentType"
          options={attachmentOptions()}
          placeholder={t`Kies een type`}
        />
      )}

      {shouldAcknowledge && (
        <DatePickerField
          isFutureDisabled
          label={t`Datum van erkenning/betekening`}
          name="acknowledgedDate"
          placeholder={t`Datum van erkenning/betekening`}
        />
      )}

      {hasReplacementOption && (
        <Box spaceAfter={6}>
          <Checkbox
            isChecked={!!replacement}
            label={t`Vervangend register`}
            name=""
            onChange={(value) => setValue(`replacement`, value)}
          />
        </Box>
      )}
    </>
  );
};

const defaultDocumentFormValues = (
  mutation: RegisterMutation,
  effectiveDate: Date,
  canChooseType: boolean,
  isFoundation: boolean,
  isNoDocumentAllowed?: boolean,
) => {
  if (!mutation.document && !isNoDocumentAllowed) {
    const defaultDocument = {
      type: undefined as DocumentType | undefined,
      labels: mutationLabelObject[mutation.type].defaultLabels(isFoundation),
      date: effectiveDate,
      deedPassed: undefined as DeedPassed | undefined,
    };
    if (!canChooseType) defaultDocument.type = DocumentType.NOTARIAL_DEED;
    if (mutationLabelObject[mutation.type].defaultDocumentType)
      defaultDocument.type = mutationLabelObject[mutation.type].defaultDocumentType;
    return defaultDocument;
  }
  return mutation.document;
};

const defaultValues = (
  mutation: RegisterMutation,
  legalEntity: LegalEntity,
  canChooseType: boolean,
  isNoDocumentAllowed = false,
) => {
  const { type: mutationType, acknowledgedDate, title } = mutation;
  const effectiveDate =
    mutation.effectiveDate || new Date(mutation.register.legalEntity.incorporatedOn);
  const values: UploadDocumentFormValues = {
    document: defaultDocumentFormValues(
      mutation,
      effectiveDate,
      canChooseType,
      legalEntity.isFoundation,
      isNoDocumentAllowed,
    ) as DocumentUploadFormValues,
    incorporatedOn: legalEntity.incorporatedOn,
    acknowledgedDate,
    title,
    effectiveDate,
    replacement: mutation.register.replacement,
  };
  if ([MutationType.ATTACHMENT, MutationType.END_ATTACHMENT].includes(mutationType)) {
    const mutationField = mutationLabelObject[mutationType]
      .field as MutationInputFields['ATTACHMENT'];
    values.attachmentType = mutation[mutationField]?.[0]?.type;
    const defaultAttachedAt =
      mutationType === MutationType.ATTACHMENT ? dayjs().format('HH:mm') : null;
    values.attachedAt = mutation[mutationField]?.[0]?.details?.attachedAt || defaultAttachedAt;
  }
  return values;
};

const getFormSchema = (mutationType: MutationType) => {
  const formSchema = yup.object({
    title: yup.string().trim().nullable(),
    acknowledgedDate: dateBasicValidation,
    document: documentUploadFormSchema.nullable(),
  });
  if (mutationType === MutationType.ONBOARDING)
    return formSchema.concat(
      yup.object({
        incorporatedOn: dateBasicValidation.required(),
      }),
    );
  if (mutationType === MutationType.INCORPORATION)
    return formSchema.concat(
      yup.object({
        effectiveDate: dateBasicValidation.required(),
      }),
    );
  if ([MutationType.ATTACHMENT, MutationType.END_ATTACHMENT].includes(mutationType)) {
    const attachedAt = yup
      .string()
      .trim()
      .nullable()
      .matches(
        /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/,
        t`De tijd moet in het volgende formaat worden ingevoerd: "23:59"`,
      )
      .length(5);
    if (mutationType === MutationType.END_ATTACHMENT)
      return formSchema.concat(
        yup.object({
          attachedAt,
        }),
      );
    return formSchema.concat(
      yup.object({
        attachedAt: attachedAt.required(),
        attachmentType: yup.string().oneOf(Object.values(AttachmentType)).required(),
      }),
    );
  }

  return formSchema;
};

export const UploadDocument = () => {
  const { previousStep, nextStep, visitStep, mutation, saveMutation } = useWizardContext();
  const { type: mutationType, register } = mutation;
  const { legalEntity } = register;
  const labels = useLabels('uploadDocument', legalEntity.isFoundation);
  const uploadDocument = useUploadDocument();
  const [deleteFile] = useMutation<DeleteFile, DeleteFileVariables>(DELETE_FILE);
  const [updateRegister] = useMutation<UpdateRegisterMutation, UpdateRegisterMutationVariables>(
    UPDATE_REGISTER_MUTATION,
  );
  const [, setGlobalError] = useGlobalError();
  const [updateLegalEntityQuery] = useUpdateLegalEntity();

  const canChooseType = mutationLabelObject[mutationType].hasDocumentChoiceStep(
    legalEntity.isFoundation,
  );
  const isNoDocumentAllowed = !!mutationLabelObject[mutationType].isNoDocumentAllowed;
  const formSchema = useMemo(() => getFormSchema(mutationType), [mutationType]);
  const form = useForm<UploadDocumentFormValues>({
    defaultValues: defaultValues(mutation, legalEntity, canChooseType, isNoDocumentAllowed),
    resolver: yupResolver(formSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const reset = useFormReset(form);
  const { getValues, handleSubmit, setValue } = form;
  const { isSubmitting, isValid } = form.formState;
  const isSubmitDisabled = !isValid || isSubmitting;
  const documentType = form.watch('document.type');
  const document = form.watch('document');
  const shouldAcknowledge = mutationLabelObject[mutationType].shouldAcknowledge;

  const setRegister = useCallback(
    async ({ replacement }: UploadDocumentFormValues) => {
      await updateRegister({
        variables: {
          registerId: register.id,
          register: {
            replacement: !!replacement,
          },
        },
        onError: ({ graphQLErrors }) => {
          setFormErrors({ errors: graphQLErrors, form, setGlobalError });
        },
      });
    },
    [form, register.id, setGlobalError, updateRegister],
  );

  const setIncorporatedOn = useCallback(
    async (formValues: UploadDocumentFormValues) => {
      const incorporatedOn =
        mutationType === MutationType.INCORPORATION
          ? formValues.effectiveDate
          : formValues.incorporatedOn;

      // We only want to change incorporatedOn if it has changed from original value
      if (incorporatedOn && !isEqual(incorporatedOn, legalEntity.incorporatedOn))
        await updateLegalEntityQuery({
          variables: {
            legalEntityId: legalEntity.id,
            legalEntity: Object.assign({}, mapLegalEntityToLegalEntityInput(legalEntity), {
              incorporatedOn,
            }),
          },
          onError: ({ graphQLErrors }) => {
            setFormErrors({ errors: graphQLErrors, form, setGlobalError });
          },
        });
    },
    [form, legalEntity, mutationType, setGlobalError, updateLegalEntityQuery],
  );

  const onSubmit = useCallback(async () => {
    const formValues = getValues();
    const {
      document: documentValues,
      incorporatedOn: _incorporatedOn,
      attachmentType,
      attachedAt,
      ...mutationValues
    } = formValues;
    const document = documentValues
      ? {
          ...mutation.document,
          ...documentValues,
        }
      : null;

    if ([MutationType.INCORPORATION, MutationType.ONBOARDING].includes(mutationType)) {
      await Promise.all([setRegister(formValues), setIncorporatedOn(formValues)]);
    }

    const toSaveMutation = cloneDeep({
      ...mutation,
      document: document as any,
      ...mutationValues,
    });

    if ([MutationType.ATTACHMENT, MutationType.END_ATTACHMENT].includes(mutationType)) {
      const mutationField = mutationLabelObject[mutationType]
        .field as MutationInputFields['ATTACHMENT'];
      toSaveMutation[mutationField]?.forEach((attachment) => {
        if (attachmentType) attachment.type = attachmentType;
        if (attachedAt && attachment.details) attachment.details.attachedAt = attachedAt;
      });
    }

    if (mutationType === MutationType.ONBOARDING)
      toSaveMutation.effectiveDate = documentValues?.date;

    await saveMutation({
      mutation: toSaveMutation,
      onCompleted: async ({ updateMutation }) => {
        if (updateMutation.document && formValues.document?.file?.content) {
          await uploadDocument({
            document: updateMutation.document,
            file: formValues.document.file,
            onDocumentSave: async () => {
              await reset(formValues);
              visitStep(nextStep);
            },
          });
        } else if (updateMutation.document?.file && !formValues.document?.file) {
          await deleteFile({
            variables: {
              documentId: updateMutation.document.id,
            },
            onCompleted: async () => {
              await reset(formValues);
              visitStep(nextStep);
            },
          });
        } else {
          await reset(formValues);
          visitStep(nextStep);
        }
      },
      onError: async ({ graphQLErrors }) => {
        setFormErrors({
          errors: graphQLErrors,
          form,
          setGlobalError,
        });
      },
    });
  }, [
    deleteFile,
    form,
    getValues,
    mutation,
    mutationType,
    nextStep,
    reset,
    saveMutation,
    setGlobalError,
    setIncorporatedOn,
    setRegister,
    uploadDocument,
    visitStep,
  ]);

  const canChooseDate = ![MutationType.ATTACHMENT, MutationType.END_ATTACHMENT].includes(
    mutationType,
  );
  const isOnboarding = mutationType === MutationType.ONBOARDING;
  const headerDocumentLabel = isOnboarding
    ? labels.uploadDocumentOnboarding
    : labels.uploadDocument;
  const subHeaderDocumentLabel = documentType ? formatDocumentType(documentType, mutationType) : '';
  const defaultMutationTypeName = formatMutationType({ type: mutationType });

  return (
    <FormProvider {...form}>
      <WizardHeader previousStep={previousStep} title={headerDocumentLabel}>
        <Trans>Voeg de {subHeaderDocumentLabel} toe en de bijbehorende gegevens.</Trans>
      </WizardHeader>

      <Columns count={2}>
        <Column>
          {mutationType === MutationType.ONBOARDING && (
            <DatePickerField
              isFutureDisabled
              required
              label={t`Oprichtingsdatum`}
              name="incorporatedOn"
              placeholder={t`Oprichtingsdatum`}
              spaceAfter={6}
            />
          )}

          <InputField
            label={t`Titel rechtsfeit`}
            name="title"
            placeholder={defaultMutationTypeName}
            spaceAfter={6}
          />

          {isNoDocumentAllowed && (
            <Box spaceAfter={6}>
              <Checkbox
                isChecked={document === null}
                label={t`Geen document toevoegen`}
                name=""
                onChange={(value) => {
                  if (value)
                    setValue('document', null, { shouldValidate: true, shouldDirty: true });
                  else {
                    const { document } = defaultValues(mutation, legalEntity, canChooseType);
                    setValue('document', document, { shouldValidate: true, shouldDirty: true });
                  }
                }}
              />
            </Box>
          )}

          {(mutationType === MutationType.INCORPORATION || !document) && (
            <DatePickerField
              required
              label={t`Datum inwerkingtreding`}
              name="effectiveDate"
              placeholder={t`Datum inwerkingtreding`}
              spaceAfter={6}
            />
          )}

          {!document && (
            <CustomMutationFields
              mutationType={mutationType}
              shouldAcknowledge={shouldAcknowledge}
            />
          )}
        </Column>
      </Columns>

      {document && (
        <DocumentUploadForm
          canChooseDate={canChooseDate}
          canChooseType={canChooseType}
          cardBackgroundVariant="white"
          isFoundation={legalEntity.isFoundation}
          mutationType={mutationType}
          nestedField="document."
        >
          <CustomMutationFields mutationType={mutationType} shouldAcknowledge={shouldAcknowledge} />
        </DocumentUploadForm>
      )}

      <WizardFooter
        isLoading={isSubmitting}
        label={t`Sla de gegevens van het document op en ga verder om alle gegevens nog eens te controleren.`}
      >
        <Button isDisabled={isSubmitDisabled} variant="primary" onClick={handleSubmit(onSubmit)}>
          <Trans>Volgende</Trans>
        </Button>
      </WizardFooter>
    </FormProvider>
  );
};

import type { MutationHookOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { UPDATE_LEGAL_ENTITY } from 'graphql/queries/legalEntity';
import type {
  UpdateLegalEntity,
  UpdateLegalEntityVariables,
} from 'types/graphql/UpdateLegalEntity';

const useUpdateLegalEntity = (
  options?: MutationHookOptions<UpdateLegalEntity, UpdateLegalEntityVariables>,
) => useMutation<UpdateLegalEntity, UpdateLegalEntityVariables>(UPDATE_LEGAL_ENTITY, options);

export { useUpdateLegalEntity };

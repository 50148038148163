import type { RegisterMutation } from '@elseu/sdu-evidend-graphql';
import { PartyType } from '@elseu/sdu-evidend-graphql';
import type { StepProps } from '@elseu/sdu-titan';
import { WizardContent } from 'components/Wizard/WizardContent';
import { getPartyTypeUrl } from 'helpers/getFromParty';
import React from 'react';

import { useWizardContext } from './useWizardContext';

export interface IWizard {
  children: React.ReactNode;
  closeTo?: string;
  defaultStep: string | ((mutation: RegisterMutation) => string);
  stepValidator: (step: string, steps: StepProps[]) => boolean;
}

export const Wizard = ({ children, ...props }: IWizard) => {
  const { step, steps, visitStep, mutation } = useWizardContext();

  const partyUrl = getPartyTypeUrl({
    type: PartyType.LEGAL_ENTITY,
    id: mutation.register.legalEntity.id,
  });
  return (
    <WizardContent
      closeTo={`${partyUrl}?tab=Mutaties`}
      mutation={mutation}
      mutationType={mutation.type}
      step={step}
      steps={steps}
      visitStep={visitStep}
      {...props}
    >
      {children}
    </WizardContent>
  );
};

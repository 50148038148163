import type { MutationType, RegisterMutation } from '@elseu/sdu-evidend-graphql';
import { PartyType } from '@elseu/sdu-evidend-graphql';
import type { StepProps } from '@elseu/sdu-titan';
import { FullscreenLayout, Link, Stepper } from '@elseu/sdu-titan';
import { type IWizard } from 'components/Wizard/Wizard';
import { formatMutationType } from 'helpers/formatMutation';
import { getPartyTypeUrl } from 'helpers/getFromParty';
import { PageTitle } from 'helpers/PageTitle';
import { capitalize, isFunction } from 'lodash';
import { useRouter } from 'next/router';
import type { FormEvent } from 'react';
import { useCallback, useEffect } from 'react';
import { usePrevious } from 'react-use';

import { substepAllowed } from './helpers';

interface IWizardContent extends IWizard {
  mutation?: RegisterMutation;
  mutationType: MutationType;
  step: string;
  steps: StepProps[];
  visitStep: (step: string, replace?: boolean | undefined) => void;
}
export const WizardContent = ({
  children,
  mutation,
  mutationType,
  step,
  steps,
  visitStep,
  closeTo = '/',
  defaultStep,
  stepValidator,
}: IWizardContent) => {
  const router = useRouter();

  // Redirect to specified `closeTo` location when the FullscreenLayout is closed.
  const onClose = useCallback(() => router.push(closeTo), [router, closeTo]);

  // Callback when a Stepper item is clicked.
  const onStepChange = useCallback(
    async (newStep: string) => {
      // Ignore changing to the current step.
      if (step === newStep) return;

      // Stepper prevents moving to a step if the step before it is not yet completed.
      // It does not prevent moving to a substep if the substep before is not yet completed.
      const isSubstepAllowed = substepAllowed(steps, newStep);
      if (isSubstepAllowed) {
        visitStep(newStep);
      }
    },
    [visitStep, step, steps],
  );

  // Remember previous step.
  const previousStep = usePrevious(step);

  // Redirect invalid or missing step.
  useEffect(() => {
    // Only validate step if it changed.
    if (previousStep === step) return;

    const isStepValid = stepValidator(step, steps);
    if (isStepValid) {
      return;
    }
    // Determine the default step.
    const hasDefaultStep = typeof defaultStep === 'string';
    if (hasDefaultStep) {
      visitStep(defaultStep, true);
      return;
    }
    const hasFallbackStep = isFunction(defaultStep) && !!mutation;
    if (hasFallbackStep) {
      visitStep(defaultStep(mutation), true);
    }
  }, [stepValidator, defaultStep, step, steps, visitStep, mutation, previousStep]);

  // Prevent form submission using the Enter key within the entire wizard.
  const preventSubmit = useCallback((event: FormEvent) => event.preventDefault(), []);

  const legalEntity = mutation?.register.legalEntity;
  const sidebar = (
    <>
      {legalEntity && (
        <Link
          spaceAfter={10}
          to={getPartyTypeUrl({
            id: legalEntity.id,
            type: PartyType.LEGAL_ENTITY,
          })}
        >
          {legalEntity.name}
        </Link>
      )}
      <Stepper activeStep={step} steps={steps} onChange={onStepChange} />
    </>
  );
  const title = `${formatMutationType({
    type: mutationType,
    title: mutation?.title || '',
  })} | ${capitalize(step)}`;

  return (
    <FullscreenLayout isModal isShown brandName="Evidend" sidebar={sidebar} onClose={onClose}>
      <PageTitle title={title} />
      <form autoComplete="off" onSubmit={preventSubmit}>
        {children}
      </form>
    </FullscreenLayout>
  );
};
